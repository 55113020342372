export const environment : {production : boolean, cognito : any, awsconfig: any, aws_url : string } = {
  production: false,
  cognito : {
    region: 'eu-central-1',
   // userPoolId: 'eu-central-1_klXImYA4a',
    userPoolId: 'eu-central-1_PWYOkL2xk',
    // userPoolWebClientId: '258rc67grq2kbnp6ae3qtsvs97',
    userPoolWebClientId: '4olh8l5s96g27a2b4hsn4oaui'
  },
  awsconfig : {
    'aws_project_region': 'eu-central-1',
    'aws_users_pools' : 'enable',
    'aws_cognito_region': 'eu-central-1',
    'aws_user_pools_id': 'eu-central-1_PWYOkL2xk',
    'aws_user_pools_web_client_id': '4olh8l5s96g27a2b4hsn4oaui'
  },
  aws_url: "https://backend-api.e.euro-connect.cloud"
};

